<template>
  <div id="main" class="kefan">
    <div class="selection">
      <el-row :gutter="20">
        <el-col :span="14">
          <el-select
            v-model="value"
            clearable
            placeholder="请选择园区"
            @change="changeyuanqu"
          >
            <el-option
              v-for="(item, index) in option"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changevalue"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3" style="display: flex; align-items: left">
          <el-button type="primary" @click="search(value)">查询</el-button>
          <el-button type="success" @click="dataexport(value)">导出</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tag">
      <el-tag type="info">今日总计收款：{{ sum }}</el-tag>
    </div>
    <div class="data">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="30"> </el-table-column>
        <el-table-column prop="contract" label="合同名称" width="250">
        </el-table-column>
        <el-table-column
          prop="office"
          label="关联房间"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ra1"
          label="账单周期"
          width="250"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="type" label="账单类型" width="100">
        </el-table-column>
        <el-table-column prop="amountmoney" label="应收金额"> </el-table-column>
        <el-table-column
          prop="state"
          label="付款状态"
          :filters="[
            { text: '未付款', value: '未付款' },
            { text: '部分付', value: '部分付' },
            { text: '已支付', value: '已支付' },
          ]"
          :filter-method="filterTag"
          filter-placement="bottom-end"
        >
          <template slot-scope="scope">
            <el-tag
              :type="
                scope.row.state === '已支付'
                  ? 'success'
                  : scope.row.state === '部分付'
                  ? 'warning'
                  : 'danger'
              "
              disable-transitions
              >{{ scope.row.state }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="dt" label="支付时间"> </el-table-column>
        <el-table-column prop="money" label="该日支付金额"> </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <span class="demonstration"></span>
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      tableData: [],
      value1: "",
      value: "zhw",
      timelist: [],
      pagesize: 10,
      currentPage: 1,
      sum: 0,
      total: 0,
      option: [
        {
          value: "zhw",
          label: "智慧湾",
        },
        {
          value: "qlf",
          label: "七立方",
        },
        {
          value: "fcx",
          label: "凤城巷",
        },
        {
          value: "fhy",
          label: "凤凰园",
        },
        {
          value: "thgj",
          label: "同和国际大厦",
        },
        {
          value: "zhs",
          label: "智慧树",
        },
        {
          value: "tkgj",
          label: "天科国际大厦",
        },
        {
          value: "zhf",
          label: "智慧坊",
        },
        {
          value: "zhq",
          label: "智慧桥",
        },
        {
          value: "bdw",
          label: "半岛湾",
        },
        {
          value: "mzy",
          label: "明珠园",
        },
        {
          value: "zhx",
          label: "智慧线",
        },
        {
          value: "gsy",
          label: "智慧线冠生园",
        },
        {
          value: "khl",
          label: "科慧楼",
        },
      ],
    };
  },
  mounted() {
    this.getdate();
  },
  methods: {
    // 导出当前查询的数据
    dataexport(value) {
      if (this.value1 !== null) {
        axios({
          method: "post",
          url: this.$host + this.$url.billexcel,
          headers: {
            'Content-Type':'application/json',
          },
          responseType:'blob',
          data: {
            name: value,
            timelist: this.timelist,
          },
        }).then((res)=>{
          let url=window.URL.createObjectURL(new Blob([res.data]));
          let link=document.createElement('a')
          link.download=this.value+'账单实收表.xls'
          link.href=url
          link.click()
        });
      }else{
        this.$notify({
          title: "查询失败",
          message: "请先选择日期范围",
          type: "error",
        });
      }
    },
     // 切换园区
    changeyuanqu(){
      this.search(this.value)
    },
    // 获取今天的日期和明天的日期对象
    getdate() {
      axios({
        method: "get",
        url: this.$host + this.$url.init,
      }).then((res) => {
        console.log(res.data);
        this.value1 = res.data;
        this.timelist = res.data;
        this.search(this.value);
      });
    },
    // 查询日期范围内的账单核销记录
    search(value) {
      if (this.value1 !== null) {
        this.timelist = this.value1;
        axios({
          method: "post",
          url: this.$host + this.$url.bill,
          data: {
            timelist: this.timelist,
            name: value,
            currentPage: this.currentPage,
            pagesize: this.pagesize,
          },
        }).then((res) => {
          console.log(res.data);
          this.total = res.data.total;
          this.sum = res.data.sum;
          this.tableData = res.data.data;
        });
      } else {
        this.timelist = [],
        this.$notify({
          title: "查询失败",
          message: "请先选择日期范围",
          type: "error",
        });
      }
    },
    filterTag(value, row) {
      return row.state === value;
    },
    // 时间范围
    changevalue() {
      if (this.value1 !== null) {
        // this.timelist = [];
        // var start = this.formatDate(this.value1[0]);
        // var end = this.formatDate(this.value1[1]);
        // this.timelist.push(start, end);
        // console.log(this.timelist)
        this.timelist = this.value1;
        console.log(this.timelist);
      }

      // console.log(this.timelist)
    },
   
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 分页处理
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.search(this.value);
    },
  },
};
</script>
<style scoped>
.page {
  display: flex;
  justify-content: right;
}
.tag {
  display: flex;
  justify-content: right;
  margin: 10px 10px;
}
</style>
